import { ReactElement } from 'react'

export interface HelpButtonProps {
  onClick: () => void
}

export default function HelpButton ({ onClick }: HelpButtonProps): ReactElement {
  return <button onClick={onClick} className="fill-tint/20 hover:fill-tint/50 transition-colors duration-200">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 48 48' className="w-12 h-12"><path d="M24.1 35.5q.85 0 1.55-.675.7-.675.7-1.575 0-.9-.675-1.55t-1.575-.65q-.9 0-1.575.65-.675.65-.675 1.55t.675 1.575q.675.675 1.575.675ZM22 28.3h3.6q0-1.15.325-2.2.325-1.05 1.825-2.35 1.5-1.1 2.1-2.375.6-1.275.6-2.875 0-2.75-1.6-4.425-1.6-1.675-4.4-1.675-2.65 0-4.65 1.4-2 1.4-2.95 3.7l3.35 1.25q.55-1.4 1.6-2.175 1.05-.775 2.25-.775 1.25 0 2.1.75.85.75.85 2 0 .9-.55 1.825-.55.925-1.55 1.725-1.4 1.15-2.15 2.6-.75 1.45-.75 3.6Zm2 16.75q-4.35 0-8.2-1.625-3.85-1.625-6.725-4.5Q6.2 36.05 4.575 32.2 2.95 28.35 2.95 24t1.625-8.2q1.625-3.85 4.5-6.725Q11.95 6.2 15.8 4.55q3.85-1.65 8.15-1.65 4.4 0 8.275 1.65t6.725 4.525q2.85 2.875 4.5 6.725 1.65 3.85 1.65 8.25 0 4.3-1.65 8.15-1.65 3.85-4.525 6.725-2.875 2.875-6.725 4.5-3.85 1.625-8.2 1.625Z"/></svg>
  </button>
}
